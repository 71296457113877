import { CardElement } from '@stripe/react-stripe-js'

import * as styles from './StripeCardElement.css'

export type StripeCardEventError = {
  code: string
  type: string
  message: string
}

export type StripeCardEvent = {
  elementType: string
  value: {
    postalCode: string
  }
  empty: boolean
  complete: boolean
  brand: string
  error?: undefined | StripeCardEventError
}

type StripeCardElementProps = {
  onCardChange: (event: StripeCardEvent) => void
}

export const StripeCardElement = ({ onCardChange }: StripeCardElementProps) => (
  <CardElement
    options={{
      style: {
        base: {
          fontSize: '16px',
          color: 'white',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#718096',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
      classes: {
        base: styles.cardElementBase,
        focus: styles.cardElementFocus,
        invalid: styles.cardElementInvalid,
      },
      hidePostalCode: true,
      iconStyle: 'solid',
    }}
    onChange={onCardChange}
  />
)
